<template>
    <StepTemplate class="login">
        <template v-slot:headerTitle>
            Авторизация
        </template>
        <div
            v-if="isSbg"
            class="login__slider"
        >
            <VueSlickCarousel :infinite="false" :centerMode="true" :variableWidth="true" :arrows="false" :dots="true">
                <div class="login__slide login__first-loan"></div>
                <div class="login__slide login__online">
                    Все операции<br/> онлайн
                </div>
                <div class="login__slide login__questions">
                    <p>
                        Частые вопросы
                    </p>
                    <p>
                        Что делать если у меня изменился номер телефона?
                    </p>
                    <p>
                        Вам необходимо связаться
                        с оператором по телефону
                        {{ phone }} и пройти
                        повторную аутентификацию.
                    </p>
                </div>
            </VueSlickCarousel>
        </div>
        <router-view/>
    </StepTemplate>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import Cookies from 'js-cookie'
import Store from '@/store'
import './login.scss';
import contacts from '@/mixins/contacts';
import sbg from '@/mixins/sbg';

export default {
    name: 'LoginPage',
    mixins: [
        contacts,
        sbg,
    ],
    components: {
        VueSlickCarousel,
    },
    async beforeRouteEnter(to, from, next) {
        if (Cookies.get('sbg-in')) {
            Store.commit('application/load', false)
            next('/lk')
        } else {
            next()
        }
    }
}
</script>